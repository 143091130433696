import React from 'react';

import NewsArticle from '../../../components/news-article/news-article.component'

import { LATEST_RATE_SHEET } from '../../../data/latest-rate-sheet.data';

const Article = () => (
  <NewsArticle>
    <h1 className='page-title'>Changes to our mortgage products</h1>
    <p>With effect from Sunday 1 May 2022 we will be making the following changes to our range:</p>
    <p><strong>Summary of changes</strong></p>
    <p>With effect from Sunday 1 May 2022 we are changing our Residential Standard Variable Rate from 3.79% to 4.04% and our Buy to Let Standard Variable Rate from 4.85% to 5.10%.</p>
    <p>Any customers who are currently on our Residential or BTL Standard Variable Rates will be notified of changes to their payments in writing.</p>
    <p>There are no further changes to any of our other rates.</p>
    <p>An updated mortgage rate sheet will be published on Sunday 1 May 2022 to reflect these changes.</p>
    <p><a title="This PDF opens in a new window" target="_blank" href={LATEST_RATE_SHEET.href}>Link to our latest rate sheet</a></p>
  </NewsArticle>
)

export default Article